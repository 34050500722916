import {Controller} from "stimulus"
import SignaturePad from "signature_pad"
import tr from "vanillajs-datepicker/locales/tr";

export default class extends Controller {
  static targets = ["layer", "canvas", "imageUploader", "imagePreviewArea", "menu", "signatureButton"]

  initialize() {
    this.signaturePad = new SignaturePad(this.canvasTarget, {
      backgroundColor: "rgb(255, 255, 255)",
    });
  }

  clear() {
    this.signaturePad.clear();
  }

  show() {
    this.layerTarget.classList.remove("hidden");
  }

  cancel() {
    this.signaturePad.clear();
    this.layerTarget.classList.add("hidden");
  }

  async complete() {
    try {
      const dataUrl = this.signaturePad.toDataURL("image/png")
      const base64Response = await fetch(dataUrl);
      const blob = await base64Response.blob()
      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(new File([blob], "file"))
      this.imageUploaderTarget.files = dataTransfer.files;

      this.imagePreviewAreaTarget.innerHTML = ""
      const img = document.createElement("img");
      img.src = dataUrl;
      this.imagePreviewAreaTarget.appendChild(img);

      // 후 처리
      this.layerTarget.classList.add("hidden");
      this.menuTarget.classList.add("hidden");

      this.replaceOutline()
    } catch (error) {
      console.error(error);
    }
  }

  replaceOutline() {
    this.signatureButtonTarget.classList.remove('btn-red-outline')
    this.signatureButtonTarget.classList.add('btn-primary-outline')
  }
}
