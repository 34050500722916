import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["allCheckbox", "checkbox"]

  connect() {
    this.element.controllers = this.element.controllers ?? {}
    this.element.controllers[this.identifier] = this
  }

  onChanged() {
    this.allCheckboxTarget.checked = this.checkboxTargets.every((target) => target.checked == true)
  }

  checkAll(_, checkedParam = null) {
    this.checkboxTargets.forEach((target) => {
      target.checked = checkedParam ? checkedParam : this.allCheckboxTarget.checked
    })
  }

  get checked() {
    return this.checkboxTargets.filter((target) => target.checked)
  }
}
