import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["startedAt", "endedAt"]

  connect() {
    this.element.controllers = this.element.controllers ?? {}
    this.element.controllers[this.identifier] = this
  }

  calc(event) {
    if (!(this.startedAtTarget && this.startedAtTarget.value)) return

    this.endedAtTarget.value = this.dateString(
      this.addMonth(
        new Date(this.startedAtTarget.value),
        Number(event.target.value)
      )
    )

    this.fireChangeEvent(this.endedAtTarget)
  }

  addMonth(date, month) {
    const newDate = new Date(date)

    newDate.setMonth(date.getMonth() + Number(month))
    newDate.setDate(date.getDate() - 1)

    return newDate
  }

  dateString(date) {
    const newMonth = (date.getMonth() + 1).toString().padStart(2, "0")
    const newDay = date.getDate().toString().padStart(2, "0")

    return `${date.getFullYear()}.${newMonth}.${newDay}`
  }

  fireChangeEvent(element) {
    const event = new Event("change")
    element.dispatchEvent(event)
  }

  get startedAt() {
    return this.root.querySelector('input[name*="[started_at]"]')
  }

  get root() {
    return this.element.parentElement
  }
}
