import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dateInput" ]

  connect() {
    this.initCalendars(this.dateInputTargets);
  }

  initCalendars(elements) {
    elements.forEach(el => {
      const datepicker = new Datepicker(el, {
        autohide: true,
        daysOfWeekDisabled: [],
        daysOfWeekHighlighted: [],
        // defaultViewDate: cdate,
        nextArrow: '>>',
        prevArrow: '<<',
        todayButton: false,
        todayButtonMode: 1,
        todayHighlight: true,
        format: 'yyyy.mm.dd'
      });
    });
  }
}
