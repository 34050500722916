import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "mainCategory", "category" ]
  static values = {
    contactType: String,
    contractType: String,
    mainCategory: String,
    category: String,
    mainCategoryPath: String,
    categoryPath: String
  }

  connect() {
    this.element.controllers = this.element.controllers ?? {}
    this.element.controllers[this.identifier] = this

    this.request_main_category(
      this.contactTypeValue, this.contractTypeValue, this.mainCategoryValue, this.categoryValue)
  }

  update_main_category(event) {
    if (event.target.tagName !== 'INPUT') return

    this.request_main_category(event.target.value, this.contractTypeValue)
  }

  request_main_category(
    contactTypeValue, contractTypeValue, defaultMainCategoryValue, defaultCategoryValue) {
    const url = this.getUrl(
      this.mainCategoryPathValue,
      [
        { key: "contact_type", value: contactTypeValue },
        { key: "contract_type", value: contractTypeValue }
      ])

    fetch(url)
      .then(this.checkStatus)
      .then(response => response.text())
      .then(html => this.mainCategoryTarget.innerHTML = html)
      .then(() => this.setValue(this.mainCategoryTarget, defaultMainCategoryValue))
      .then(() => this.request_category(this.mainCategoryTarget.value, defaultCategoryValue))
  }

  update_category(event) {
    this.request_category(event.target.value)
  }

  request_category(mainCategoryId, defaultValue) {
    if (!mainCategoryId) return;

    const url = this.getUrl(this.categoryPathValue,
      { key: "main_category_id", value: mainCategoryId })

    fetch(url)
      .then(this.checkStatus)
      .then(response => response.text())
      .then(html => this.categoryTarget.innerHTML = html)
      .then(() => this.setValue(this.categoryTarget, defaultValue))
  }

  getUrl(path, params) {
    const url = new URL(path, window.location.origin)
    const paramList = Array.isArray(params) ? params : Array.of(params)

    paramList.forEach(el => url.searchParams.set(el['key'], el['value']))

    return url
  }

  load(url, targetEl) {
    // console.log('url', url)
    fetch(url)
      .then(this.checkStatus)
      .then(response => response.text())
      .then(html => targetEl.innerHTML = html)
  }

  checkStatus(response) {
    if (!response.ok) throw Error(response.statusText)
    return response
  }

  init(element) {
    [...element.getElementsByTagName('INPUT')][0].checked = true
  }

  reset(element) {
    [...element.getElementsByTagName('INPUT')].forEach(element => element.checked = false)
  }

  setValue(element, value) {
    if (element.tagName !== 'SELECT') return

    element.value = (!!value) ? value : element.options[0].value
  }
}
