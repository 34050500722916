import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tag", "moreTag", "closeTag", "popup"];

  connect() {
    this.visibleTagTargets();
    this.moreTagsVisibility();
    this.truncateTagTexts();
  }

  visibleTagTargets() {
    this.tagTargets.forEach((tag, index) => {
      if (index >= 3) {
        tag.classList.add("hidden");
      }
    });
  }

  moreTagsVisibility() {
    if (this.tagTargets.length > 3) {
      this.moreTagTarget.classList.remove("hidden");
    }
  }

  showExtraTags() {
    this.tagTargets.slice(3).forEach((tag) => {
      tag.classList.remove("hidden");
    });
    this.moreTagTarget.classList.add("hidden");
    this.closeTagTarget.classList.remove("hidden");
  }

  hideExtraTags() {
    this.tagTargets.slice(3).forEach((tag) => {
      tag.classList.add("hidden");
    });
    this.moreTagTarget.classList.remove("hidden");
    this.closeTagTarget.classList.add("hidden");
  }

  truncateTagTexts() {
    const maxLength = 8;
    this.tagTargets.forEach((tagElement) => {
      const originalTagText = tagElement.textContent.trim();
      const truncatedTagText = this.truncateTagText(originalTagText, maxLength);

      if (originalTagText.length > maxLength) {
        const circleElement = tagElement.querySelector("svg");
        tagElement.dataset.originalTagText = originalTagText;
        tagElement.dataset.truncatedTagText = truncatedTagText;
        tagElement.textContent = truncatedTagText;
        tagElement.prepend(circleElement);
        tagElement.addEventListener("mouseenter", this.showFullTagText.bind(this));
        tagElement.addEventListener("mouseleave", this.showTruncatedTagText.bind(this));
      }
    });
  }

  showFullTagText(event) {
    const tagElement = event.currentTarget;

    this.popupTarget.textContent = tagElement.dataset.originalTagText;
    this.popupTarget.classList.remove("hidden");
    this.popupTarget.style.top = `${tagElement.offsetTop - this.popupTarget.offsetHeight - 5}px`;
    const left = ((tagElement.offsetLeft + tagElement.offsetWidth / 2) - (this.popupTarget.offsetWidth / 2));
    this.popupTarget.style.left = `${left}px`;
  }

  showTruncatedTagText() {
    this.popupTarget.classList.add("hidden");
  }

  truncateTagText(tagText, maxLength) {
    if (tagText.length > maxLength) {
      return tagText.slice(0, maxLength) + "...";
    }
    return tagText;
  }
}
