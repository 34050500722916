// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import { installErrorHandler } from "@appsignal/stimulus"
import appsignal from "../appsignal"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
installErrorHandler(appsignal, application)
application.load(definitionsFromContext(context))

// 3rd party libraries
import { Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
import Reveal from "stimulus-reveal-controller"
// import Clipboard from "stimulus-clipboard"

application.register("dropdown", Dropdown)
application.register("modal", Modal)
application.register("tabs", Tabs)
application.register("popover", Popover)
application.register("toggle", Toggle)
application.register("slideover", Slideover)
application.register("reveal", Reveal)
// application.register("clipboard", Clipboard)
