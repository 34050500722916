import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "email" ]

  connect() {
    this.element.controllers = this.element.controllers ?? {}
    this.element.controllers[this.identifier] = this
  }

  copy(event) {
    this.emailTargets.forEach(el => el.value = event.target.value)
  }
}
