// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "controllers"
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "@oddcamp/cocoon-vanilla-js";
import process from "process"

// window.Rails = Rails;
// if(Rails.fire(document, "rails:attachBindings")) {
//     Rails.start();
// }
Rails.start()
ActiveStorage.start()

require("stylesheets/application.scss")

import toastr from "toastr"
window.toastr = toastr

import { Datepicker } from "vanillajs-datepicker"
window.Datepicker = Datepicker

// Iamport
require("jquery")
require("./plugins/iamport.payment-1.1.8.js")
const Iamport = window.IMP
Iamport.init(process.env["iamport_client_uid"])
console.log("iamport loaded.")

// hotwire
import "@hotwired/turbo-rails"

// request.js
import "@rails/request.js"
