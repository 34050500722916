import { Controller } from "stimulus"

export default class extends Controller {
  static targets =
    [ 'imageDropArea', 'imagePreviewArea', 'imageUploader', 'signatureLeft', 'signatureTop' ]

  connect() {
    this.element.controllers = this.element.controllers ?? {}
    this.element.controllers[this.identifier] = this

    this.init()
  }

  init() {
    this.initDrop()
    // this.initDrag()
  }

  initDrop() {
    if (!this.hasImageDropAreaTarget) return

    const fileInput = document.createElement("input")
    fileInput.type = "file"
    fileInput.accept = "image/*"
    fileInput.multiple = false

    this.imageDropAreaTarget.addEventListener('click', () => fileInput.click())

    fileInput.addEventListener("change", () => this.handleFiles(fileInput.files))

    this.imageDropAreaTarget.addEventListener('dragenter', this.preventDefault, false)
    this.imageDropAreaTarget.addEventListener('dragleave', this.preventDefault, false)
    this.imageDropAreaTarget.addEventListener('dragover', this.preventDefault, false)
    this.imageDropAreaTarget.addEventListener('drop', this.handleDrop, false)
  }

  preventDefault(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  handleFiles(files) {
    if (!this.validateImage(files[0])) return

    this.previewImage(files[0])
    this.setImageToInput(files)
  }

  handleDrop(e) {
    const data = e.dataTransfer,
      files = data.files;

    if (files.length) this.handleFiles(files)
  }

  validateImage(image) {
    // check the type
    const validTypes = ['image/jpeg', 'image/png', 'image/gif']

    if (validTypes.indexOf( image.type ) === -1) {
        alert("Invalid File Type")
        return false
    }

    // check the size
    const maxSizeInBytes = 10e6 // 10MB

    if (image.size > maxSizeInBytes) {
        alert("Too large file")
        return false
    }

    return true
  }

  previewImage(image) {
    // container
    const imgView = document.createElement("div");
    imgView.className = "image-view";

    while (this.imagePreviewAreaTarget.firstChild) {
      this.imagePreviewAreaTarget.removeChild(this.imagePreviewAreaTarget.firstChild);
    }

    this.imagePreviewAreaTarget.classList.add("bg-white")
    this.imagePreviewAreaTarget.appendChild(imgView);

    // previewing image
    const img = document.createElement("img");
    imgView.appendChild(img);

    // read the image...
    const reader = new FileReader()
    reader.onload = (e) => img.src = e.target.result
    reader.readAsDataURL(image)
  }

  setImageToInput(files) {
    this.imageUploaderTarget.files = files
  }

  initDrag() {
    if (!this.hasImageDropAreaTarget) return

    document.getElementsByName("dragme").forEach(el => {
      el.addEventListener('mousedown', (event) => this.startDrag(el, event), true)
      document.addEventListener('mousemove', (event) => this.onDrag(el, event), true)
      document.addEventListener('mouseup', () => this.stopDrag(), true)
    })
  }

  startDrag(el, event) {
    this.clicked = true

    this.offsetX = el.offsetLeft - event.clientX
    this.offsetY = el.offsetTop - event.clientY
  }

  onDrag(el, event) {
    event.preventDefault();

    if (!this.clicked) return

    el.style.left = this.offsetX + event.clientX + 'px'
    el.style.top = this.offsetY + event.clientY + 'px'

    this.signatureLeftTarget.value = el.style.left
    this.signatureTopTarget.value = el.style.top
  }

  stopDrag() {
    this.clicked = false
  }
}
