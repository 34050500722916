import { Controller } from "stimulus"

export default class extends Controller {

  successType = "success"
  createSuccessMessage = "성공하였습니다."

  static targets = [ 'item' ]
  static values = { text: String }

  initialize() {
    this.textValue = this.itemTarget.innerText
  }

  connect() {
    this.element.controllers = this.element.controllers ?? {}
    this.element.controllers[this.identifier] = this
  }

  spin() {
    this.itemTarget.innerHTML = `${this.spinIcon} ${this.spinText}`
  }

  unspin(type, message) {
    this.itemTarget.innerText = this.textValue
    this.notify(type ?? this.successType, message ?? this.createSuccessMessage)
  }

  notify(type, message) {
    this.alertController?.notify([[type, message]])
  }

  get spinIcon() {
    return `
    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
    `
  }

  get spinText() {
    return '처리중...'
  }

  get alertController() {
    return document.getElementById('alert')?.controllers["alert"]
  }
}
