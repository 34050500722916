import { Controller } from "stimulus"

export default class extends Controller {
  static values = { path: String }

  connect() {
    this.element.controllers = this.element.controllers ?? {}
    this.element.controllers[this.identifier] = this
  }

  move() {
    window.location = this.pathValue
  }
}
