import {Controller} from "stimulus"

export default class extends Controller {

  static targets = [
    'form',
    'submitBtn',
    'wireTransferTaxInvoiceRequired',
    'cmsBankTaxInvoiceRequired',
    'cmsCardTaxInvoiceRequired',
    'cmsBankAccountType'
  ]

  connect() {
    this.element.controllers = this.element.controllers ?? {}
    this.element.controllers[this.identifier] = this

    this.init()
  }

  init() {
    // Init click event
    document
      .getElementsByName('contract_document[billing_type]')
      .forEach(el =>
        el.addEventListener('click', (evt) => this.handleBillingTypeEvent(evt.target))
      )

    // Init required fields
    document
      .getElementsByName('contract_document[billing_type]')
      .forEach(el => {
        if (el.checked) this.handleBillingTypeEvent(el)
      })
  }

  handleBillingTypeEvent(enabledTarget) {
    document
      .getElementsByName('contract_document[billing_type]')
      .forEach(el => this.enableSelectedBillingType(el, enabledTarget))

    this.initRequiredRadioButton()
  }

  enableSelectedBillingType(el, enabledTarget) {
    const isEnabled = el === enabledTarget

    el.checked = isEnabled

    document
      .querySelectorAll(`input[name^="contract_document[${el.value}"]`)
      .forEach(input => this.enableSelectedBillingTypeInputs(input, isEnabled))

    document
      .querySelectorAll(`select[name^="contract_document[${el.value}"]`)
      .forEach(input => this.enableSelectedBillingTypeInputs(input, isEnabled))
  }

  enableSelectedBillingTypeInputs(input, isEnabled) {
    input.disabled = !isEnabled
    input.required = isEnabled

    if (!isEnabled && input.type !== "radio") input.value = ""
    if (!isEnabled) input.checked = false
  }

  initRequiredRadioButton() {
    const wireTransferTaxInvoiceRequired = this.wireTransferTaxInvoiceRequiredTarget.getAttribute('data-contract-document-target-value')
    const cmsBankTaxInvoiceRequired = this.cmsBankTaxInvoiceRequiredTarget.getAttribute('data-contract-document-target-value')
    const cmsCardTaxInvoiceRequired = this.cmsCardTaxInvoiceRequiredTarget.getAttribute('data-contract-document-target-value')
    const cmsBankAccountType = this.cmsBankAccountTypeTarget.getAttribute('data-contract-document-target-value')

    if(!cmsCardTaxInvoiceRequired){
      this.handleCmsCardInvoceRequired("false")
    }

    if(!cmsBankTaxInvoiceRequired){
      this.handleCmsBankInvoceRequired("false")
    }

    if(!wireTransferTaxInvoiceRequired){
      this.handleWireTransferInvoceRequired("false")
    }

    this.handleCmsBankRequired(cmsBankAccountType)
  }

  setCmsBankRequired(event) {
    this.handleCmsBankRequired(event.target.value)
  }

  handleCmsBankRequired(value) {
    const billingTypeCmsBank = document
      .getElementById("contract_document_billing_type_cms_bank")

    const newValue = billingTypeCmsBank.checked && value

    const personEl = document.getElementById("contract_document_cms_bank_account_birthday")
    const personParentEl = personEl.parentElement.parentElement
    const companyEl = document.getElementById("contract_document_cms_bank_account_business_number")
    const companyParentEl = companyEl.parentElement.parentElement

    if (newValue === "person") {
      personEl.required = true
      personParentEl.classList.remove("hidden")
      companyEl.required = false
      companyEl.value = ""
      companyParentEl.classList.add("hidden")
    } else if (newValue === "company") {
      personEl.required = false
      personEl.value = ""
      personParentEl.classList.add("hidden")
      companyEl.required = true
      companyParentEl.classList.remove("hidden")
    } else {
      personEl.required = false
      personEl.value = ""
      personParentEl.classList.add("hidden")
      companyEl.required = false
      companyEl.value = ""
      companyParentEl.classList.add("hidden")
    }
  }

  setWireTransferInvoceRequired(event) {
    this.handleWireTransferInvoceRequired(event.target.value)
  }

  handleWireTransferInvoceRequired(value) {
    const issuedDayEl = document
      .getElementById("contract_document_wire_transfer_tax_invoice_issued_day")
    const issuedDayParentEl = issuedDayEl.parentElement.parentElement.parentElement
    const recepientEl = document
      .getElementById("contract_document_wire_transfer_tax_invoice_recipient")
    const recepientParentEl = recepientEl.parentElement.parentElement

    if (value === "true") {
      issuedDayEl.required = true
      issuedDayParentEl.classList.remove("hidden")
      recepientEl.required = true
      recepientParentEl.classList.remove("hidden")
    } else {
      issuedDayEl.required = false
      issuedDayEl.value = ""
      issuedDayParentEl.classList.add("hidden")
      recepientEl.required = false
      recepientEl.value = ""
      recepientParentEl.classList.add("hidden")
    }
  }

  setCmsBankInvoceRequired(event) {
    this.handleCmsBankInvoceRequired(event.target.value)
  }

  handleCmsBankInvoceRequired(value) {
    const issuedDayEl = document
      .getElementById("contract_document_cms_bank_tax_invoice_issued_day")
    const issuedDayParentEl = issuedDayEl.parentElement.parentElement.parentElement
    const recepientEl = document
      .getElementById("contract_document_cms_bank_tax_invoice_recipient")
    const recepientParentEl = recepientEl.parentElement.parentElement

    if (value === "true") {
      issuedDayEl.required = true
      issuedDayParentEl.classList.remove("hidden")
      recepientEl.required = true
      recepientParentEl.classList.remove("hidden")
    } else {
      issuedDayEl.required = false
      issuedDayEl.value = ""
      issuedDayParentEl.classList.add("hidden")
      recepientEl.required = false
      recepientEl.value = ""
      recepientParentEl.classList.add("hidden")
    }
  }

  setCmsCardInvoceRequired(event) {
    this.handleCmsCardInvoceRequired(event.target.value)
  }

  handleCmsCardInvoceRequired(value) {
    const recepientEl = document
      .getElementById("contract_document_cms_card_tax_invoice_recipient")
    const recepientParentEl = recepientEl.parentElement.parentElement

    if (value === "true") {
      recepientEl.required = true
      recepientParentEl.classList.remove("hidden")
    } else {
      recepientEl.required = false
      recepientEl.value = ""
      recepientParentEl.classList.add("hidden")
    }
  }

  submitForm(event) {
    event.preventDefault()

    this.submitBtnTarget.disabled = true
    this.submitBtnTarget.value = "제출중..."

    let isValidForm = this.validateForm()

    if (isValidForm) {
      this.formTarget.submit()
    } else {
      this.submitBtnTarget.disabled = false
      this.submitBtnTarget.value = "제출"
    }
  }

  validateForm() {
    let isValid = true
    let requiredFields = this.requiredFields()

    requiredFields.forEach((field) => {
      if (!this.isFilled(field)) {
        this.notify(
          "warning",
          (field.type === "file" && field.id !== "file-input") ? "도장 이미지를 업로드해야 합니다." : "필수 입력사항입니다.")

        field.focus()
        isValid = false
      }
    })

    isValid = isValid && this.validateAgreement()

    return isValid
  }

  requiredFields() {
    return this.formTarget.querySelectorAll("textarea:required, input:required, select:required")
  }

  validateAgreement() {
    const el = [
      "contract_document_privacy_agreement_1_false",
      "contract_document_privacy_agreement_2_false",
      "contract_document_privacy_agreement_3_false"
    ]
      .map(el => document.getElementById(el))
      .find(el => el.checked)

    if (el) {
      el.focus()
      this.notify("warning", "필수 동의사항입니다.")
      return false
    }

    return true
  }

  isFilled(field) {
    if (field.disabled) return false
    if (field.type !== "radio" && !field.value.trim()) return false
    if (field.type === "radio" && !this.isRaidioButtonChecked(field)) return false

    return true
  }

  isRaidioButtonChecked(field) {
    return [...document.getElementsByName(field.name)].some(el => el.checked)
  }

  notify(type, message) {
    this.alertController?.notify([[type, message]])
  }

  get alertController() {
    return document.getElementById('alert')?.controllers["alert"]
  }
}
