import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    title: String,
    amount: Number,
    paymentCode: String,
    customerCode: String,
    userEmail: String,
    userName: String,
    orderShowPath: String
  }

  connect() {
    this.element.controllers = this.element.controllers ?? {}
    this.element.controllers[this.identifier] = this
  }

  request_pay() {
    console.log('pay title', this.titleValue)
    console.log('pay amount', this.amountValue)
    console.log('pay payment code', this.paymentCodeValue)
    console.log('pay customer code', this.customerCodeValue)
    console.log('pay email', this.userEmailValue)
    console.log('pay username', this.userNameValue)

    IMP.request_pay({
      pg : 'danal_tpay',
      pay_method : 'card',
      merchant_uid : this.paymentCodeValue,
      customer_uid : this.customerCodeValue,
      name : this.titleValue,
      amount : this.amountValue,
      buyer_email : this.userEmailValue,
      buyer_name : this.userNameValue,
    }, (rsp) => {

      if ( rsp.success ) {

        console.log('success', rsp)

        const url = this.getUrl(this.orderShowPathValue, { key: "result", value: "true" })

        // 성공 후 페이지 이동
        // $('.modal').modal('hide');
        window.location.href = url
        // window.location.href = this.orderShowPathValue

      } else {
          var msg = '결제에 실패하였습니다.';
          msg += '에러내용 : ' + rsp.error_msg;

          alert(msg);
          // $('.modal').modal('hide');
      }
    })

    return false
  }

  getUrl(path, params) {
    const url = new URL(path, window.location.origin)

    const paramList = Array.isArray(params) ? params : Array.of(params)

    // url.pathname = path
    paramList.forEach(el => url.searchParams.set(el['key'], el['value']))
    console.log('pay complete url', url)

    return url
  }
}
