import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "table", "columnInfo"]

  clearInput() {
    this.inputTarget.value = "";
    this.tableTargets.forEach(row => {
      row.classList.remove("hidden");
    });

    event.preventDefault();
  }

  showTable() {
    const searchValue = this.inputTarget.value.toLowerCase();

    this.tableTargets
      .filter(row => row.dataset.searchTable.toLowerCase().includes(searchValue))
      .forEach(row => row.classList.remove("hidden"));

    this.tableTargets
      .filter(row => !row.dataset.searchTable.toLowerCase().includes(searchValue))
      .forEach(row => row.classList.add("hidden"));

    event.preventDefault();
  }

  showColumnInfo(event) {
    const tableName = event.target.closest("li").dataset.tableName;

    this.columnInfoTargets
      .filter(columnInfo => columnInfo.dataset.tableName === tableName)
      .forEach(columnInfo => columnInfo.classList.remove("hidden"));

    this.columnInfoTargets
      .filter(columnInfo => columnInfo.dataset.tableName !== tableName)
      .forEach(columnInfo => columnInfo.classList.add("hidden"));

    event.preventDefault();
  }
}
