import { Controller } from "stimulus"
import { DateRangePicker } from 'vanillajs-datepicker'

export default class extends Controller {
  static targets = ["submit", "dateRangeField"]

  initialize() {
    new DateRangePicker(this.dateRangeFieldTarget, { format: 'yyyy.mm.dd' })
  }

  changeViewType(e) {
    e.preventDefault()
    this.submitTarget.submit();
  }
}

