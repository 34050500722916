import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["key", "value", "formValue"]

  handledChange() {
    let textArray = []
    for(let i = 0; i < this.keyTargets.length; i++) {
      textArray.push(`\"${this.keyTargets[i].value}\":\"${this.valueTargets[i].value}\"`)
    }
    this.formValueTarget.value = "{" + textArray.join(",") + "}"
  }
}
