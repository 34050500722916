import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["idsInputTemplate", "idsInputs"]

  connect() {
    this.element.controllers = this.element.controllers ?? {}
    this.element.controllers[this.identifier] = this
  }

  submit(evt) {
    evt.preventDefault()

    const checkedIds = this.checkBoxListController?.checked?.map((target) => target.value)

    if (!checkedIds || checkedIds.length < 1) {
      this.notify("error", "체크박스를 선택해 주세요.")
      return
    }

    // Clear previous ids
    Array.from(this.idsInputsTarget.children).forEach((row) => row.remove())

    // Create input fields
    checkedIds
      .map((_) => this.idsInputTemplateTarget.content.cloneNode(true))
      .forEach((el) => this.idsInputsTarget.appendChild(el))

    // Set id values
    Array.from(this.idsInputsTarget.children).map((el, index) => {
      el.value = checkedIds[index]
      el.removeAttribute("data-checkbox-actions-target")
    })

    this.element.submit()
  }

  notify(type, message) {
    this.alertController?.notify([[type, message]])
  }

  get alertController() {
    return document.getElementById("alert")?.controllers["alert"]
  }

  get checkBoxListController() {
    return document.getElementById("checkbox-list")?.controllers["checkbox-list"]
  }
}
