import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["foldingButton", "expandButton", "contents"]

  initialize(){
    this.hidden_sidebar = localStorage.getItem("hidden_sidebar") === "true"
    this.show()
  }

  toggle() {
    this.hidden_sidebar = localStorage.getItem("hidden_sidebar") !== "true"
    localStorage.setItem("hidden_sidebar", this.hidden_sidebar)
    this.show()
  }

  show() {
    if (this.hidden_sidebar) {
      this.expandButtonTarget.classList.remove("hidden")
      this.foldingButtonTarget.classList.add("-translate-x-twice")
      this.contentsTarget.classList.remove("lg:pl-64")
    } else {
      this.expandButtonTarget.classList.add("hidden")
      this.foldingButtonTarget.classList.remove("-translate-x-twice")
      this.contentsTarget.classList.add("lg:pl-64")
    }
  }
}
