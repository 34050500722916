import { Controller } from "stimulus"
import { DateRangePicker } from 'vanillajs-datepicker'

export default class extends Controller {
  static targets = ["form", "textField", "dateField", "dateRangeField", "radioField"]

  initialize() {
    this.changeSearchField(this.data.get("selected"))

    new Datepicker(this.dateFieldTarget, { format: 'yyyy.mm.dd' })
    new DateRangePicker(this.dateRangeFieldTarget, { format: 'yyyy.mm.dd' })
  }

  handleSearchField(e) {
    this.clearInputFieldData()
    this.changeSearchField(e.target.value)
  }

  changeSearchField(value) {
    let fieldType = this.fieldType(value)
    let enumValues = null

    if (typeof fieldType == "object")
    {
      enumValues = fieldType[1]
      fieldType = fieldType[0]
    }

    switch(fieldType)
    {
      case "text":
        this.showOnly(this.textFieldTarget)
        break
      case "date":
        this.showOnly(this.dateFieldTarget)
        break
      case "date_range":
        this.showOnly(this.dateRangeFieldTarget)
        break
      case "enum":
        this.showOnly(this.radioFieldTarget)
        this.buildEnumList(enumValues)
        break
      default:
        this.showOnly(this.textFieldTarget)
    }
  }

  fieldType(fieldName) {
    return JSON.parse(this.data.get("fields"))[fieldName]
  }

  showOnly(showTarget) {
    this.targetFields.forEach((target) => {
      if(target == showTarget){
        target.classList.remove("hidden")
      } else {
        target.classList.add("hidden")
      }
    })
  }

  handleTextField(e) {
    this.formTarget.keyword.value = e.target.value
  }

  handleDateField(e) {
    this.formTarget.keyword.value = e.target.value
  }

  handleDateRangeField() {
    this.formTarget.keyword.value = this.formTarget.start.value + "~" + this.formTarget.end.value
  }

  handleRadioField(e) {
    this.formTarget.keyword.value = e.target.value
  }

  clearInputFieldData() {
    this.formTarget.keyword.value = ""
    this.formTarget.typed_keyword.value = ""
    this.formTarget.start.value = ""
    this.formTarget.end.value = ""
    this.dateFieldTarget.value = ""
  }

  get targetFields() {
    return [this.textFieldTarget, this.dateFieldTarget, this.dateRangeFieldTarget, this.radioFieldTarget]
  }

  buildEnumList(enumValues) {
    let bodyNode = this.radioFieldTarget.querySelector("div")
    let radioTemplateNodes = this.radioFieldTarget.querySelector('div[id="search_radio_field_template"]').childNodes

    bodyNode.innerHTML = ""
    Object.keys(enumValues).forEach((key) => {
      let radioNode = radioTemplateNodes[0].cloneNode(false)
      let labelNode = radioTemplateNodes[1].cloneNode(false)
      labelNode.innerHTML = enumValues[key]
      radioNode.checked = this.formTarget.keyword.value == key
      radioNode.value = key
      bodyNode.appendChild(labelNode)
      bodyNode.appendChild(radioNode)
    })
  }
}
