import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "keywordInput", "customerCodeInput", "options" ]
  static values = { path: String }

  connect() {
    this.load()
  }

  updateOptions() {
    this.load()
  }

  updateSelected(event) {
    const selectedEl = (event.target.tagName === 'DIV') ? event.target : event.target.parentElement

    const selectedTextVal = selectedEl.querySelector('span[name="text"]').innerText
    const selectedIdVal = selectedEl.querySelector('span[name="id"]').innerText

    this.keywordInputTarget.value = selectedTextVal
    this.customerCodeInputTarget.value = selectedIdVal
    this.customerCodeInputTarget.dispatchEvent(new InputEvent('input', { data: selectedIdVal }));

    this.toggle_options()
  }

  load() {
    // console.log('path', this.path)
    fetch(this.path)
      .then(response => response.text())
      .then(responseBody => JSON.parse(responseBody))
      .then(json => this.convert(json))
      .then(html => this.optionsTarget.innerHTML = html)
    // console.log('after load', this.optionsTarget.innerHTML)
  }

  convert(json) {
    return Array.isArray(json) ?
      this.convertToHtmls(json).join('') :
      this.convertToHtml(json)
  }

  convertToHtmls(elements) {
    return elements.map(el => this.convertToHtml(el))
  }

  convertToHtml(element) {
    return `
      <div
        data-action="click->autocomplete#updateSelected"
        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer" role="menuitem">
        <span name="text">${element.value}</span>
        <span class="hidden" name="id">${element.id}</span>
      </div>
    `
  }

  get path() {
    const params = { keyword: this.keywordInputTarget.value }
    return `${this.pathValue}?${new URLSearchParams(params).toString()}`
  }

  toggle_options() {
    this.dropdownController.toggle()
  }

  get dropdownController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "dropdown")
  }
}
