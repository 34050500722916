import { Controller } from 'stimulus'
import Chart from "../plugins/chart-3.7.1.js"

export default class extends Controller {
  static targets = ["chart"]
  static values = {
    data: String
  }

  initialize() {
    this.chartData = JSON.parse(this.dataValue)
    this.backgroundColors = [
      "hsl(252, 82.9%, 67.8%)",
      "hsl(180, 82.9%, 67.8%)",
      "hsl(200, 82.9%, 67.8%)",
    ]
    this.createChart()
  }

  createChart() {
    const datasets = this.chartData["datasets"].map((dataset) => {
      return {
        label: dataset["label"],
        backgroundColor: this.backgroundColors.pop(),
        borderColor: "hsl(252, 82.9%, 67.8%)",
        data: dataset["data"],
      }
    })

    const dataBarChart = {
      labels: this.chartData["labels"],
      datasets: datasets
    };

    const configBarChart = {
      type: "bar",
      data: dataBarChart,
      options: {},
    };

    new Chart(
      this.chartTarget,
      configBarChart
    );
  }
}
