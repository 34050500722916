import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["item", "success", "info", "warning", "error", "message"]
  static values = { flash: Array }

  initialize() {
    if (this.hasFlashValue) this.notify(this.flashValue)
  }

  connect() {
    this.element.controllers = this.element.controllers ?? {}
    this.element.controllers[this.identifier] = this
  }

  close() {
    this.itemTargets.forEach((el) => this.hide(el))
  }

  notify(flashes) {
    flashes.forEach((flash) => this.doNotify(flash))
  }

  doNotify(flash) {
    if (!flash) return

    this.setMessage(flash[1])

    const element = this.getFlashElement(flash[0])
    if (element == undefined) return

    this.show(element)
    this.hideAfter(element, 6000)
  }

  getFlashElement(type) {
    switch (type) {
      case "success":
        return this.successTarget
      case "info":
      case "notice":
        return this.infoTarget
      case "warning":
      case "alert":
        return this.warningTarget
      case "error":
        return this.errorTarget
      default:
        console.log(`Not available type: ${type}.`)
        return undefined
    }
  }

  show(element) {
    this.root.classList.add("flex")
    this.root.classList.remove("hidden")
    element.classList.remove("translate-x-twice")
    element.classList.remove("hidden")
    element.classList.add("translate-x-0")
  }

  hide(element) {
    element.classList.remove("translate-x-0")
    element.classList.add("translate-x-twice")
    element.classList.add("hidden")
    this.root.classList.add("hidden")
    this.root.classList.remove("flex")
  }

  hideAfter(element, time) {
    setTimeout(() => {
      this.hide(element)
    }, time)
  }

  setMessage(message) {
    this.messageTargets.forEach(
      (element) =>
        (element.innerText = (typeof message === "object" ? message.join("\n") : message) ?? "")
    )
  }

  get root() {
    return this.element.parentElement
  }
}
