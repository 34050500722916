import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "contract", "contractItemContents", "newOrderButton", "newOrderItems", "orderRelease"]

  connect() {
    this.element.controllers = this.element.controllers ?? {}
    this.element.controllers[this.identifier] = this
  }

  updateSelectedCallback(event) {
    let contractId = event.data
    this.updateOrderItems(contractId)
    this.updateOrderReleases(contractId)
  }

  async updateOrderItems(contractId) {
    const path = `/admin/contracts/${contractId}/contract_items`
    const contractItems = await fetch(path)
                             .then(response => response.text())
                             .then(responseBody => JSON.parse(responseBody))
    this.fillContractItemsOrderRelease(contractItems)
    this.fillContractItems(contractItems)
  }

  fillContractItemsOrderRelease(contractItems) {
    let html = []
    contractItems.forEach((contractItem) => {
      if(!!contractItem.order_release.id)
        html.push(`${contractItem.product.name} | ${contractItem.order_release.week_and_day_i18n} 출고`)
    })
    this.contractItemContents.innerHTML = html.join("<br>")
  }

  fillContractItems(contractItems) {
    if(!this.hasNewOrderButtonTarget)
      return

    this.newOrderItemsTarget.innerHTML = "";
    contractItems.forEach((contractItem) => {
      this.newOrderButtonTarget.click()

      this.dropdownProductInput.value = contractItem.product.name
      this.productId.value = contractItem.product_id
      this.price.value = contractItem.price
      this.quantity.value = contractItem.quantity
    })
  }

  async updateOrderReleases(contractId) {
    if(!this.hasOrderReleaseTarget)
      return

    const path = `/admin/contracts/${contractId}/order_releases`
    const orderReleases = await fetch(path)
                            .then(response => response.text())
                            .then(responseBody => JSON.parse(responseBody))
    const html = orderReleases.map((orderRelease) =>
      `<option value='${orderRelease.id}'>
        ${orderRelease.contract_item.product.name} | ${orderRelease.contract_item.quantity} 개 | 각 ${orderRelease.contract_item.price} 원
      </option>`
    ).join()
    this.orderReleaseSelectBox.innerHTML = "<option>선택해주세요</option>" + html
  }

  get lastOrderItem() {
    return this.newOrderItemsTarget.lastElementChild
  }

  get dropdownProductInput() {
    return this.lastOrderItem.querySelector('input[name*="[dropdown_product_input]"]')
  }

  get productId() {
    return this.lastOrderItem.querySelector('input[name*="[product_id]"]')
  }

  get price() {
    return this.lastOrderItem.querySelector('input[name*="[price]"]')
  }

  get quantity() {
    return this.lastOrderItem.querySelector('input[name*="[quantity]"]')
  }

  get contractItemContents() {
    const nodeList = this.contractItemContentsTarget.querySelectorAll(":last-child")
    return nodeList[nodeList.length-1]
  }

  get orderReleaseSelectBox() {
    return this.orderReleaseTarget.querySelector("select")
  }
}
