import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.element.controllers = this.element.controllers ?? {}
    this.element.controllers[this.identifier] = this

    this.reactviatedAt.addEventListener("change", () => this.updateNewContractDates())
    this.reactviatedAt.addEventListener("changeDate", () => this.updateNewContractDates())
  }

  disconnect() {
    this.reactviatedAt.removeEventListener("change", this.updateNewContractDates)
    this.reactviatedAt.removeEventListener("changeDate", this.updateNewContractDates)
  }

  updateNewContractDates() {
    if (!(this.suspendedAt?.value && this.reactviatedAt?.value)) return

    this.updateNewContractStartedAt()
    this.updateNewContractEndedAt()
  }

  updateNewContractStartedAt() {
    const newStartedAtDate = new Date(this.reactviatedAt.value)

    newStartedAtDate.setDate(newStartedAtDate.getDate() + 1)

    this.startedAt.value = this.dateString(newStartedAtDate)
  }

  updateNewContractEndedAt() {
    const newDate = new Date(this.uneditedEndedAt.value)

    const suspendedAt = new Date(this.suspendedAt.value)
    const reactviatedAt = new Date(this.reactviatedAt.value)
    const millisecDiff = reactviatedAt - suspendedAt

    newDate.setMilliseconds(newDate.getMilliseconds() + millisecDiff)

    this.endedAt.value = this.dateString(newDate)
  }

  dateString(date) {
    const newMonth = (date.getMonth() + 1).toString().padStart(2, "0")
    const newDay = date.getDate().toString().padStart(2, "0")

    return `${date.getFullYear()}.${newMonth}.${newDay}`
  }

  get startedAt() {
    return this.root.querySelector('input[name*="[started_at]"]')
  }

  get endedAt() {
    return this.root.querySelector('input[name*="[ended_at]"]')
  }

  get uneditedEndedAt() {
    return this.root.querySelector('input[name*="unedited_ended_at"]')
  }

  get suspendedAt() {
    return this.root.querySelector('input[name*="[suspended_at]"]')
  }

  get reactviatedAt() {
    return this.root.querySelector('input[name*="[reactivated_at]"]')
  }

  get root() {
    return this.element.parentElement
  }
}
