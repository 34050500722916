import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["list"];

  initialize() {
    if (JSON.parse(this.data.get("collection")).length === 0)
      this.emptyCollection();
  }

  handleDisplay() {
    this.listTargets[0].classList.toggle("block");
    this.listTargets[0].classList.toggle("hidden");
    this.listTargets[1].classList.toggle("hidden");
    this.listTargets[1].classList.toggle("block");
    this.listTargets[2].classList.toggle("hidden");
    this.listTargets[2].classList.toggle("block");
  }

  emptyCollection() {
    this.listTargets[0].classList.remove("block");
    this.listTargets[0].classList.add("hidden");
    this.listTargets[1].classList.remove("hidden");
    this.listTargets[1].classList.add("block");
    this.listTargets[2].classList.remove("block");
    this.listTargets[2].classList.add("hidden");
  }
}
