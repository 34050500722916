import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'button', 'source' ]
  static values = {
    successDuration: Number
  }

  connect () {
    if (!this.hasButtonTarget) return

    this.originalText = this.buttonTarget.innerText
    this.successDuration = this.successDurationValue || 2000
  }

  copy (event) {
    event.preventDefault()

    window.getSelection().removeAllRanges()

    // this.sourceTarget.select()
    if (document.selection) {
      const div = document.body.createTextRange()

      div.moveToElementText(this.soureTarget)
      div.select()
    } else if (window.getSelection) {
      const div = document.createRange()

      div.setStartBefore(this.sourceTarget)
      div.setEndAfter(this.sourceTarget)

      window.getSelection().addRange(div)
    } else {
      console.log("Not supported")
    }

    document.execCommand('copy')
    window.getSelection().removeAllRanges()

    this.copied()
  }

  copied () {
    if (!this.hasButtonTarget) return

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.buttonTarget.innerText = this.data.get('successContent')

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerText = this.originalText
    }, this.successDuration)
  }
}
