import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["row", "root", "child"]

  downTriangle = "&#9660;" // ▼
  rightTriangle = "&#9654;" // ▶

  toggle() {
    this.childTargets.forEach((child) => child.classList.toggle("table-row-hidden"))
    this.toggleTriangle()
  }

  toggleTriangle() {
    this.targetEl.innerHTML = this.isRightTriangle ? this.downTriangle : this.rightTriangle
  }

  htmlEncode(s) {
    return Array.from(s)
      .map((char) => (char.codePointAt(0) > 127 ? `&#${char.codePointAt(0)};` : char))
      .join()
  }

  get isRightTriangle() {
    return this.htmlEncode(this.targetEl.innerHTML.trim()) == this.rightTriangle
  }

  get targetEl() {
    return this.element.querySelector("td[name='triangle']")
  }
}
