import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "contract" ]

  downTriangle = "&#9660;" // ▼
  rightTriangle = "&#9654;" // ▶

  toggle() {
    let contractItemNode = this.contractTarget.nextElementSibling
    contractItemNode.classList.toggle("table-row-hidden")

    this.toggleTriangle()
  }

  toggleTriangle() {
    this.targetEl.innerHTML = this.isRightTriangle ? this.downTriangle : this.rightTriangle
  }

  htmlEncode(s) {
    var ret_val = '';
    for (let char of s) {
      const code = char.codePointAt(0);
      if (code > 127) {
        ret_val += '&#' + code + ';';
      } else {
        ret_val += char;
      }
    }
    return ret_val;
  }

  get isRightTriangle() {
    return this.htmlEncode(this.targetEl.innerHTML.trim()) == this.rightTriangle
  }

  get targetEl() {
    return this.element.querySelector("td[name='triangle']")
  }
}
