import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "keywordInput", "customerCodeInput", "options" ]
  static values = { path: String, releasableItemType: String }

  connect() {
    this.load()
  }

  updateOptions() {
    this.load()
  }

  updateSelected(event) {
    const productEl = (event.target.tagName === 'DIV') ? event.target : event.target.parentElement

    const textVal = productEl.querySelector('span[name="text"]').innerText
    const idVal = productEl.querySelector('span[name="id"]').innerText
    const defaultPriceVal = productEl.querySelector('span[name="default_price"]').innerText
    const itemTypeVal = productEl.querySelector('span[name="item_type"]').innerText

    this.keywordInputTarget.value = textVal
    this.customerCodeInputTarget.value = idVal

    this.itemPrice.value = defaultPriceVal
    this.itemType.innerText = itemTypeVal

    this.toggleOrderReleaseAttrs()

    this.toggleOptions()
  }

  load() {
    // console.log('path', this.path)
    fetch(this.path)
      .then(response => response.text())
      .then(responseBody => JSON.parse(responseBody))
      .then(json => this.convert(json))
      .then(html => this.optionsTarget.innerHTML = html)
      .then(_ => this.toggleOrderReleaseAttrs())
    // console.log('after load', this.optionsTarget.innerHTML)
  }

  convert(json) {
    return Array.isArray(json) ?
      this.convertToHtmls(json).join('') :
      this.convertToHtml(json)
  }

  convertToHtmls(elements) {
    return elements.map(el => this.convertToHtml(el))
  }

  convertToHtml(element) {
    return `
      <div
        data-action="click->autocomplete-product#updateSelected"
        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer" role="menuitem">
        <span name="text">${element.value}</span>
        <span class="hidden" name="id">${element.id}</span>
        <span class="hidden" name="default_price">${element.default_price}</span>
        <span class="hidden" name="item_type">${element.item_type}</span>
      </div>
    `
  }

  toggleOptions() {
    this.dropdownController.toggle()
  }

  toggleOrderReleaseAttrs() {
    const releasableItemTypes = JSON.parse(this.releasableItemTypeValue)

    if (releasableItemTypes.includes(this.itemType.innerText)) {
      this.itemWeek.classList.remove("hidden")
      this.itemDay.classList.remove("hidden")
    } else {
      this.itemWeek.classList.add("hidden")
      this.itemDay.classList.add("hidden")
    }
  }

  get path() {
    const params = { keyword: this.keywordInputTarget.value }
    return `${this.pathValue}?${new URLSearchParams(params).toString()}`
  }

  get itemPrice() {
    return this.root.querySelector('input[name*="[price]"]')
  }

  get itemQuantity() {
    return this.root.querySelector('input[name*="[quantity]"]')
  }

  get itemWeek() {
    return this.root.querySelector('div[name*="[week]"]')
  }

  get itemDay() {
    return this.root.querySelector('div[name*="[day]"]')
  }

  get itemType() {
    return this.root.querySelector('div[name*="[item_type]"] > div > div > div')
  }

  get root() {
    return this.element.closest("li")
  }

  get dropdownController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "dropdown")
  }
}
