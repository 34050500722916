import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tagInput", "hiddenInput", "tag", "removeTag"];

  connect() {
    this.renderExistingTags();
    // action: "keyup.space->tag#addTag" 동작하지 않음
    this.tagInputTarget.addEventListener("keyup", (event) => {
      if (event.key === " ") {
        event.preventDefault();
        this.addTag();
      }
    });
  }

  addTag() {
    const tagText = this.tagInputTarget.value.trim();
    if (tagText.length === 0) {
      return;
    }

    const tagElement = this.createTagElement(tagText);
    this.tagTarget.appendChild(tagElement);
    this.tagInputTarget.value = "";

    const tagsArray = this.hiddenInputTarget.value ? JSON.parse(this.hiddenInputTarget.value) : [];
    tagsArray.push(tagText);
    this.hiddenInputTarget.value = JSON.stringify(tagsArray);
  }

  createTagElement(tagText) {
    const template = document.getElementById("tag-template");
    const tag = document.importNode(template.content, true);
    const tagTextNode = tag.querySelector(".tag-text");
    tagTextNode.textContent = tagText;

    return tag;
  }

  removeTag(event) {
    const tagElement = event.currentTarget.closest("span");
    const tagText = tagElement.querySelector(".tag-text").textContent;

    const tagsArray = JSON.parse(this.hiddenInputTarget.value);
    const tagIndex = tagsArray.indexOf(tagText);
    if (tagIndex !== -1) {
      tagsArray.splice(tagIndex, 1);
    }
    this.hiddenInputTarget.value = JSON.stringify(tagsArray);

    tagElement.remove();
  }

  renderExistingTags() {
    const existingTags = JSON.parse(this.tagInputTarget.dataset.existingTags || "[]");
    this.hiddenInputTarget.value = JSON.stringify(existingTags);

    existingTags.forEach((tag) => {
      const tagElement = this.createTagElement(tag);
      this.tagTarget.appendChild(tagElement);
    });
  }
}
