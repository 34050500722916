import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String }

  initialize() {
    this.buttonIdMap = {
      origins: { en: "origins", ko: "공급계약" },
      revised: { en: "revised", ko: "변경계약" },
      delivered: { en: "delivered", ko: "서명대기중" },
      active: { en: "active", ko: "서명완료" },
    }
    this.targetList = ["sign", "contract"]
    this.filterParam =
      this.data.get("filter") !== "all"
        ? this.data
            .get("filter")
            .split(",")
            .map((el) => {
              return { name: this.buttonIdMap[el].ko }
            })
        : []

    this.targetList.forEach((el) => this.initialColorize(el))
    this.appendHTML()
  }

  initialColorize(target) {
    const targetInputs = Array.from(document.querySelectorAll(`input[name='${target}']`))
    targetInputs.forEach((input) => {
      if (this.data.get("filter").split(",").includes(input.id)) input.checked = true
    })
    this.colorizeBtn(target)
  }

  handleFilter(e) {
    this.colorizeBtn(e.target.name)
    this.addParams(e.target.id, e.target.name)
  }

  colorizeBtn(target) {
    const targetInputs = Array.from(document.querySelectorAll(`input[name='${target}']`))

    targetInputs.forEach((input) => {
      if (input.checked) {
        input.nextElementSibling.classList.remove("btn-outline")
        input.nextElementSibling.classList.add("btn-primary")
      } else {
        input.nextElementSibling.classList.add("btn-outline")
        input.nextElementSibling.classList.remove("btn-primary")
      }
    })
  }

  addParams(buttonId, target) {
    const checkedInputIndex = this.filterParam.findIndex(
      (el) => el.name === this.buttonIdMap[buttonId].ko
    )

    checkedInputIndex === -1 && this.filterParam.push({ name: this.buttonIdMap[buttonId].ko })

    const unCheckedInput = Array.from(document.querySelectorAll(`input[name='${target}']`)).filter(
      (el) => el !== document.querySelector(`input[name='${target}']:checked`)
    )[0]

    const unCheckedInputIndex = this.filterParam.findIndex(
      (el) => el.name === this.buttonIdMap[unCheckedInput.id].ko
    )

    unCheckedInputIndex !== -1 && this.filterParam.splice(unCheckedInputIndex, 1)

    this.appendHTML()
    this.redirectPage()
  }

  appendHTML() {
    const template = this.filterParam.map((el) => this.getTemplateString(el)).join("")
    document.getElementById("filterField").innerHTML = template
  }

  getTemplateString(element) {
    return `
    <span class="m-1 items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900">
      <span>${element.name}</span>
      <button name=${element.name} type="button" data-action="click->filter-buttons#handleCloseFilter" class="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500">
        <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
          <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </span>
    `
  }

  redirectPage() {
    const filterParamToEn = this.filterParam.map((el) => this.getEnbyKo(el.name))

    const urlParams = new URLSearchParams(window.location.search)
    this.filterParam.length !== 0
      ? urlParams.set("filter", filterParamToEn)
      : urlParams.delete("filter")
    window.location.assign(`${this.urlValue}?${urlParams}`)
  }

  handleCloseFilter(e) {
    const targetIndex = this.filterParam.findIndex((el) => el.name === e.currentTarget.name)

    this.filterParam.splice(targetIndex, 1)

    const targetInputId = this.getEnbyKo(e.currentTarget.name)

    document.getElementById(targetInputId).checked = false

    this.targetList.forEach((el) => this.colorizeBtn(el))
    this.appendHTML()
    this.redirectPage()
  }

  getEnbyKo(ko) {
    return this.buttonIdMap[
      Object.keys(this.buttonIdMap).find((key) => this.buttonIdMap[key].ko === ko)
    ].en
  }
}
