import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.element.controllers = this.element.controllers ?? {}
    this.element.controllers[this.identifier] = this

    this.init()
  }

  init() {
    document.getElementById("contract_form").addEventListener("submit", e => this.submitHandler(e))
  }

  submitHandler(e) {
    e.preventDefault()
    this.removeUnusedContractItems()
    document.getElementById("contract_form").submit()
    return true
  }

  getItemProductName(el) {
    return document.getElementById("contract_contract_item_dropdown_product_input").value
  }

  getItemProductId(el) {
    return el.querySelector('input[name*="[product_id]"]').value
  }

  getItemTypeName(el) {
    return el.querySelector('input[name*="[item_type]"] > div > div > label').textContent
  }

  getItemType(el) {
    return el.querySelector('div[name*="[item_type]"] > div > div > div').textContent
  }

  getItemQuantity(el) {
    return el.querySelector('input[name*="[quantity]"]').value
  }

  getItemPrice(el) {
    return el.querySelector('input[name*="[price]"]').value
  }

  getItemWeek(el) {
    return el.querySelector('div[name*="[week]"]')
  }

  getItemDay(el) {
    return el.querySelector('div[name*="[day]"]')
  }

  getNewContractItems() {
    return document.getElementById("new_contract_items")
  }

  getAllListEls() {
    return this.getNewContractItems().querySelectorAll("li")
  }

  getReleasableItemTypeVals() {
    const el = this.getNewContractItems().querySelector("#releasable_item_type_names")
    return JSON.parse(el.innerText)
  }

  removeUnusedContractItems() {
    [...this.getAllListEls()].forEach(el => {
      if (this.validateContractItem(el)) return
      el.remove()
    })
  }

  validateContractItem(el) {
    const productId = this.getItemProductId(el)
    const quantity = this.getItemProductId(el)
    const price = this.getItemProductId(el)

    return (!productId || !quantity || !price) ? false : true
  }
}
